/* eslint-disable import/no-named-as-default */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { array, object, oneOfType, string, bool } from 'prop-types';
import get from 'lodash/get';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import PreRender from 'yoda-site-components/lib/components/PreRender/PreRender';
import ProductListRenderer from './ProductListRenderer';
import ProductsLoader from './ProductsLoader';
import SortBy from '../SortBy/SortBy';
import VisualRelatedSearch from '../VisualRelatedSearch/VisualRelatedSearchLazy';
import DynamicGalleriesWidget from '../DynamicGalleriesWidget/DynamicGalleriesWidget';
import DynamicVisNav from '../DynamicVisNav/DynamicVisNavLazy';
import ScrollRendererWrapper from '../ScrollRendererWrapper/ScrollRendererWrapper';
import { getPageType } from '../../utils';
import { showVerticalProductImages } from '../../helpers/GalleryHelper';

// istanbul ignore next
const ProductList = ({
    gridAds,
    irisComponents,
    irisComponentsWithoutGrid,
    irisPropsData,
    marketingSlot,
    renderBrPreviewSkeletonGrid,
}) => {
    const {
        seoTags = {},
        context: {
            featureFlags: {
                enableDynamicGalleriesWidget = false,
                enableVisualRelatedSearch = false,
                enableDynamicVisNav = false,
                enableVerticalProductImages = false,
                enableVerticalImageLg = false,
            } = {},
            preferences: {
                verticalProductImageInclusionList,
                dynamicGalleriesWidget: { gridPlacementIndex = 2 } = {},
            } = {},
            requestUrl = '',
        } = {},
        relatedSearchReducer,
        dynamicVisNavReducer,
        pagination: { pagination: { selectedPageNumber: pageNumber = 0 } = {} } = {},
        productList: { products = [] } = {},
    } = useSelector((store) => store);
    const data = JSON.parse(LocalStorage.getData('StyliticsBrowsedItems', true));
    const isGallery = irisPropsData?.pageType === 'Gallery' || getPageType(requestUrl) === 'g';

    const shouldRenderWidget = (index) =>
        data?.jcpenney?.length >= 3 && isGallery && index === gridPlacementIndex;

    const enableVerticalImages = useMemo(() => {
        return showVerticalProductImages(
            enableVerticalProductImages,
            verticalProductImageInclusionList,
            irisPropsData?.pageType,
            seoTags
        );
    }, [!__SERVER__]);

    return products.length ? (
        <>
            <div className={dt(['sm:hidden', 'lg:flex', 'mb-6'])}>
                <SortBy
                    className={dt(['ml-auto'])}
                    displayDesktopView
                    renderBrPreviewSkeletonGrid={renderBrPreviewSkeletonGrid}
                />
            </div>
            <PreRender url={get(products[0], 'productList[0].pdpUrl')} />
            {products?.map((product, index) => (
                <>
                    <ProductListRenderer
                        dataSource={product}
                        gridAds={gridAds}
                        irisComponents={irisComponents}
                        irisComponentsWithoutGrid={irisComponentsWithoutGrid}
                        irisPropsData={irisPropsData}
                        key={product?.ppid}
                        listIndex={index}
                        marketingSlot={marketingSlot}
                        renderBrPreviewSkeletonGrid={renderBrPreviewSkeletonGrid}
                        enableVerticalImages={enableVerticalImages}
                        enableVerticalImageLg={enableVerticalImageLg}
                    />
                    {enableDynamicGalleriesWidget && shouldRenderWidget(index) && (
                        <DynamicGalleriesWidget products={products} />
                    )}
                    {!renderBrPreviewSkeletonGrid &&
                        index === 0 &&
                        enableVisualRelatedSearch &&
                        irisPropsData.pageType === 'Search' &&
                        pageNumber === 1 &&
                        relatedSearchReducer?.length > 0 && <VisualRelatedSearch />}

                    {!renderBrPreviewSkeletonGrid &&
                        index === 0 &&
                        enableDynamicVisNav &&
                        irisPropsData.pageType === 'Gallery' &&
                        pageNumber === 1 &&
                        dynamicVisNavReducer?.length > 0 && (
                            <ScrollRendererWrapper>
                                <DynamicVisNav />
                            </ScrollRendererWrapper>
                        )}
                </>
            ))}
        </>
    ) : (
        <ProductsLoader />
    );
};

ProductList.defaultProps = {
    gridAds: {},
    irisComponents: {},
    irisComponentsWithoutGrid: [],
    irisPropsData: {},
    marketingSlot: [],
    renderBrPreviewSkeletonGrid: false,
};

ProductList.propTypes = {
    gridAds: oneOfType([object, string]),
    irisComponents: oneOfType([array, object]),
    irisComponentsWithoutGrid: oneOfType([array, object]),
    irisPropsData: oneOfType([array, object]),
    marketingSlot: oneOfType([array, object]),
    renderBrPreviewSkeletonGrid: bool,
};

export default React.memo(ProductList);
