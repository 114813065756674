import React from 'react';
import { useSelector } from 'react-redux';
import { bool } from 'prop-types';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './ProductsLoader.css';
import ProductSkeleton from './ProductSkeleton';

const cx = classNames.bind(styles);

const ProductsLoader = ({ hasProducts }) => {
    const {
        featureFlags: { enableFourThumbnailsGrid = false },
        isPreview = false,
    } = useSelector(({ context }) => context);

    const skeletons = [];
    const prodStyles = !hasProducts
        ? dt([
              'mb-6',
              'inline-block',
              'sm:basis-[49%]',
              enableFourThumbnailsGrid ? 'md:!basis-[24%]' : 'md:basis-[32%]',
              enableFourThumbnailsGrid ? 'lg:basis-[24%]' : 'lg:basis-[32%]',
          ])
        : dt(['mb-6', 'w-full']);
    const numberOfSkeletonLoader = 48;
    const skeletonStyles = cx(prodStyles, 'productsLoader');

    for (let index = 0; index < numberOfSkeletonLoader; index += 1) {
        skeletons.push(<ProductSkeleton key={index} className={skeletonStyles} />);
    }

    return hasProducts ? (
        <ProductSkeleton className={skeletonStyles} />
    ) : (
        <div
            className={`${dt([
                'flex',
                'flex-wrap',
                'w-full',
                'justify-between',
                'sm:gap-x-1',
                'md:gap-x-2',
                isPreview ? 'lg:mb-6' : '',
            ])} ${cx('productLoaderContainer')}`}
        >
            {skeletons}
        </div>
    );
};

ProductsLoader.defaultProps = {
    hasProducts: false,
};

ProductsLoader.propTypes = {
    hasProducts: bool,
};

export default ProductsLoader;
