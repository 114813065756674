import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocaleString } from 'yoda-site-components/lib/components/Pricing/PricingHelper';
import { bool, string } from 'prop-types';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { setClearAllFilter } from 'yoda-site-components/lib/actions/ClearAllFilterAction';
import { isDesktopWidth } from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import FilterChips from '../FilterChips/FilterChips';
import { getStoreActiveTags } from '../../utils/componentUtils';
import { goUrl, getPageURLFromAPIURL, updateUrl } from '../../utils';
import updateMultiSelectFacetInfo from '../../actions/MultiSelectFilterActions';
import { setFailoverCanonicalInfo } from '../../actions/GalleryAction';
import { showLoader } from '../../actions/LoaderActions';

export const SelectedFacets = ({
    clearAllWrapperClass,
    showResultsCount,
    showTitle,
    sliderStatus,
    wrapperClass,
    inSlider,
}) => {
    const {
        context: {
            bopisInfo: { selectedStores: { selectedStoresFilter = [] } = {} } = {},
            commonData: { gallery = false, requestUrl = '' } = {},
            featureFlags: {
                enableMultiSelectFilters = false,
                enableCanonicalFallback = true,
                enableV2URL = false,
            } = {},
        } = {},
        clearAllFacets: { isVisible = false, url = '' } = {},
        countOfSearchResults = 0,
        selectedFilters = [],
    } = useSelector((store) => store);

    const noResults = gallery && !countOfSearchResults;
    const dispatch = useDispatch();

    /* istanbul ignore next */
    const setClearAllFilterSelection = () => {
        dispatch(showLoader());
        dispatch(setClearAllFilter({ isClearAllFilterApplied: true }));
        enableCanonicalFallback &&
            dispatch(
                setFailoverCanonicalInfo({
                    isUpdateCanonicalUrl: true,
                })
            );
        enableMultiSelectFilters &&
            dispatch(
                updateMultiSelectFacetInfo({
                    multiSelectedFacetsList: {},
                    scrollToTitle: true,
                    multiFacetUrl: url,
                    enableFacetLoader: true,
                    enableProductsLoader: true,
                })
            );
    };

    const getSelectedFacets = (filters) => {
        let facets = [];
        let wrapper = null;
        const wrapperClasses = `${dt([
            !sliderStatus ? 'md:px-0' : '',
            !sliderStatus ? 'lg:px-0' : '',
            sliderStatus ? 'sm:px-6' : '',
        ])} ${wrapperClass}`;

        const filterByClasses = dt(['font-open-sans-semibold', 'normal-case', 'text-lg']);

        const filterListWrapperClasses = dt([
            'sm:mt-2',
            'lg:mt-4',
            'w-full',
            'sm:mb-4',
            'flex',
            'gap-2',
            'flex-wrap',
        ]);

        const clearBtnClasses = dt([
            'active:underline',
            'focus:underline',
            'hover:underline',
            'text-sm',
            'underline',
            'visited:underline',
        ]);

        const searchResultCount = () =>
            !noResults &&
            countOfSearchResults && (
                <>
                    {getLocaleString(countOfSearchResults)}
                    {countOfSearchResults > 1 ? ' results' : ' result'}
                </>
            );

        /* istanbul ignore next */
        const clearAllFacets = () => {
            try {
                LocalStorage.setData('faceteventtriggered', true); // always true when click event triggered from clear all
                if (enableV2URL) {
                    const pageUrl = getPageURLFromAPIURL(url);

                    goUrl(pageUrl);
                } else {
                    updateUrl(url);
                }
                setClearAllFilterSelection();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        };

        if (filters.length > 0) {
            filters.map((category) =>
                category.facetValueList.map((key) => {
                    let ele = '';
                    if (key.selected === true) {
                        const selectedkey = key;
                        selectedkey.facetType = category.facetType;
                        selectedkey.facetName = category.facetName;
                        selectedkey.facetInternalName = category.facetInternalName;
                        const keyName = selectedkey.name + category.facetName;
                        ele = facets.push(<FilterChips filter={selectedkey} key={keyName} />);
                        facets = facets.concat(
                            getStoreActiveTags(key, selectedStoresFilter, requestUrl)
                        );
                    }

                    return ele;
                })
            );

            wrapper = (
                <div className={wrapperClasses}>
                    <div className={clearAllWrapperClass}>
                        {showTitle &&
                            (isDesktopWidth() ? (
                                <h3 className={filterByClasses}>Filter</h3>
                            ) : (
                                <span
                                    className={dt([
                                        'font-open-sans-semibold',
                                        'normal-case',
                                        'lg:text-sm',
                                        'sm:text-lg',
                                        'text-black',
                                    ])}
                                >
                                    Selected Filters
                                </span>
                            ))}
                        {isVisible && url && (
                            <button
                                className={clearBtnClasses}
                                onClick={clearAllFacets}
                                type="button"
                                data-automation-id={`filters-clearall-${
                                    inSlider ? 'galleryslider' : 'filterpanel'
                                }`}
                            >
                                Clear All
                            </button>
                        )}
                    </div>
                    {isDesktopWidth() && showResultsCount && (
                        <div className={dt(['mb-2'])}>{searchResultCount()}</div>
                    )}
                    <ul className={filterListWrapperClasses}>{facets}</ul>
                </div>
            );
        } else {
            wrapper =
                isDesktopWidth() && showTitle && showResultsCount ? (
                    <div className={wrapperClasses}>
                        <div className={clearAllWrapperClass}>
                            <h3 className={filterByClasses}>Filter</h3>
                        </div>
                        <div className={dt(['mb-2'])}>{searchResultCount()}</div>
                    </div>
                ) : null;
        }
        return wrapper;
    };

    return getSelectedFacets(selectedFilters);
};

SelectedFacets.defaultProps = {
    clearAllWrapperClass: dt(['flex', 'justify-between', 'w-full']),
    showResultsCount: true,
    showTitle: true,
    sliderStatus: false,
    wrapperClass: '',
    inSlider: false,
};

SelectedFacets.propTypes = {
    clearAllWrapperClass: string,
    showResultsCount: bool,
    showTitle: bool,
    sliderStatus: bool,
    wrapperClass: string,
    inSlider: bool,
};

export default SelectedFacets;
