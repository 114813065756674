import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import { getPageType, getPageURL } from '.';
import { source, isGalleryURL, url } from '../common/constants';

function findProp(obj, key, out) {
    const sourceUrl = getPageURL();
    const splitUrl = sourceUrl.split('&');
    const sourceUrlFound = splitUrl && splitUrl.find((element) => element.includes(source));
    let i;
    const proto = Object.prototype;
    const ts = proto.toString;
    const hasOwn = proto.hasOwnProperty.bind(obj);

    /* eslint-disable-next-line no-param-reassign */
    if (ts.call(out) !== '[object Array]') out = [];

    /* eslint-disable-next-line no-restricted-syntax */
    /* istanbul ignore next */
    for (i in obj) {
        if (hasOwn(i)) {
            if (i === key) {
                const isGallery = obj[i].includes(isGalleryURL);
                const isRRParam = obj[i].includes(sourceUrlFound);
                if (sourceUrlFound && isGallery && !isRRParam) {
                    /* eslint no-param-reassign: ["error", { "props": false }] */
                    obj[i] = obj[i].concat(`&${sourceUrlFound}`);
                }
                out.push(obj[i]);
            } else if (
                ts.call(obj[i]) === '[object Array]' ||
                ts.call(obj[i]) === '[object Object]'
            ) {
                findProp(obj[i], key, out);
            }
        }
    }

    return out;
}

/**
 *  to search componentName from irisData.
 *  @param {string} componentName
 *  @param {object} irisData
 */

export const irisDataProcessor = (irisData, componentName) => {
    if (!componentName) {
        // If not filter return immediately
        return irisData;
    }
    // Get all content row nodes and iterate
    const rows = _get(irisData, 'content.rows');
    let component = [];
    _forEach(rows, (rowsItem) => {
        // for each row iterate columns
        const cols = _get(rowsItem, 'columns');
        _forEach(cols, (colsItem) => {
            // for each cols iterate components
            const comps = _get(colsItem, 'components');
            _forEach(comps, (compsItem) => {
                findProp(compsItem, url);
                const componentType = _get(compsItem, 'componentType');
                if (componentType === componentName) {
                    component = compsItem;
                    return false;
                }
                return true;
            });
        });
    });
    return _get(component, 'content');
};

/**
 *  to format BreadCrumIrisData to current Data.
 *  @param {object} breadCrumbsData
 */
export const transformBreadCrumbsInfoIris = (breadCrumbsData) => {
    let breadCrumbsArr = [];
    /* istanbul ignore next */
    if (breadCrumbsData && breadCrumbsData.breadcrumbs) {
        const breadcrumbsArray = breadCrumbsData.breadcrumbs;
        const lastBreadcrumb = breadcrumbsArray[breadcrumbsArray.length - 1];
        const { deptId = '' } = lastBreadcrumb;
        breadCrumbsArr = breadcrumbsArray.map((val) => {
            const { linkText: breadCrumbLabel = '', catId = '' } = val;
            const { link: { url: pageUrl = '' } = {} } = val;
            const urlParams = new URLSearchParams(pageUrl?.split('?')[1])?.get('id') || '';
            const id = urlParams || catId || deptId;
            let breadcrumbsObj = {};
            breadcrumbsObj = {
                breadCrumbLabel,
                navigationState: pageUrl,
                pageUrl,
                deptId,
                catId: id,
            };
            return breadcrumbsObj;
        });
    }
    return breadCrumbsArr;
};

export const transformBreadCrumbsInfoSolar = (breadCrumbsData = []) => {
    let breadCrumbsArr = [];
    /* istanbul ignore else */
    if (breadCrumbsData.length > 0) {
        breadCrumbsArr = breadCrumbsData.map(
            ({ pageUrl: navigationState, breadCrumbLabel, pageUrl, deptId, catId }) => ({
                breadCrumbLabel,
                navigationState,
                pageUrl,
                deptId,
                catId,
            })
        );
    }
    return breadCrumbsArr;
};

/**
 *  to format SeoTagsIrisData to current Data.
 *  @param {object} seoData
 */

export const transformSeoTagsInfoFromIris = (seoData = {}) => {
    const seoDataObj = {};
    /* mapping from Iris seo params */
    if (!_isEmpty(seoData)) {
        seoDataObj.copyText = seoData.onPagecopy;
        seoDataObj.seoMetaTitle = seoData.pageTitle;
        seoDataObj.seoMetaKeyword = seoData.keywords;
        seoDataObj.ampUrl = seoData.ampURL;
        seoDataObj.seoMetaDescription = seoData.metaDescription;
        seoDataObj.ampUrlNext = seoData.ampURL ? `amp${seoData.ampURL}` : undefined;
        seoDataObj.nextUrl = seoData.nextURL;
        seoDataObj.h1Data = seoData.h1Tag;
        seoDataObj.canonicalURL = seoData.canonicalUrl;
        seoDataObj.noIndexNoFollowRequired = seoData.noIndexNoFollow;
    }
    return { ...seoDataObj, ...seoData };
};

export const transformVisNavDataFromIris = (
    items = [],
    disableProductCountGallery,
    disableProductCountSearch,
    showProductCount
) => {
    let datasource = [];
    let count;
    if (items.length > 3) {
        datasource = items.map((value) => {
            const {
                linkText: title = '',
                image: imageUrl = {},
                link: linkUrl = {},
                analyticsTag = '',
                openInNewTab = false,
                productCount,
            } = value;
            const pageName = getPageType();
            /* istanbul ignore next */
            if (productCount) {
                if (
                    (pageName === 'g' && (!showProductCount || disableProductCountGallery)) ||
                    (pageName === 's' && (!showProductCount || disableProductCountSearch))
                ) {
                    count = '';
                } else {
                    count = `(${productCount})`;
                }
            }
            const { url: image = '', dimensions: { width = '', height = '' } = {} } = imageUrl;
            const { url: links = '' } = linkUrl;

            return { title, image, links, analyticsTag, openInNewTab, count, width, height };
        });
    }
    return { datasource };
};

export const getValidMarketingTiles = (irisData = {}) => {
    const gridComponent = irisDataProcessor(irisData, 'gridcomponent') || {};
    const marketingTiles =
        (Array.isArray(gridComponent.items) && gridComponent.items.filter((x) => x)) || [];
    return marketingTiles;
};
