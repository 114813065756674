/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import Accordion from 'yoda-core-components/lib/components/Accordion/accordion';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { bool } from 'prop-types';
import {
    isDesktopWidth,
    isTabletWidth,
    isMobileWidth,
} from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import StickyBetween from 'yoda-site-components/lib/components/StickyBetween/StickyBetween';
// import withPerformanceMeasurement from 'yoda-core-components/lib/hoc/PerformanceObserver/HOCPerfMeasure';
import * as styles from './FilterPanel.css';
import FilterCategory from '../FilterCategory/FilterCategory';
import SelectedFacets from '../SelectedFacets/SelectedFacets';
import StoreFilterCategory from '../StoreFilterCategory/StoreFilterCategory';
import updateMultiSelectFacetInfo from '../../actions/MultiSelectFilterActions';
import { getPageType, getURLParameterBykey, updateUrl } from '../../utils';
import { scrollTitleIntoView } from '../../utils/scrollTitleIntoView';

const cx = classNames.bind(styles);

export const FilterPanel = ({ inSlider, isGalleryFilter }) => {
    const {
        context: {
            featureFlags: {
                enableMultiSelectFilters = false,
                enableStickyFacet = false,
                enableBrandNameCasing = false,
                enableOverflowFilters = false,
            } = {},
            deviceType: { isDesktop = false } = {},
            isNative = false,
        } = {},
        countOfSearchResults = 0,
        filters: { filterList = [] } = {},
        multiSelectFilter: { multiFacetUrl = '', multiSelectedFacetsList = {} } = {},
        sliderStatus = false,
    } = useSelector((store) => store);

    const dispatch = useDispatch();
    const offSetTopParentRef = useRef();
    const stickyDiv = useRef();

    const hasFilters = filterList && filterList.length > 0;
    const isTabletView = isTabletWidth() && !sliderStatus;
    const isDesktopView = isDesktopWidth();
    const [expandOnSearch, setExpandOnSearch] = useState(false);
    const isMobileView = isMobileWidth();
    /* istanbul ignore next */
    useEffect(() => {
        if (!__SERVER__ && enableOverflowFilters && isDesktopView) {
            window.addEventListener('popstate', function (event) {
                // You can also access the state object
                const offsetHeightstickycomponent = document.getElementsByClassName(
                    'stickycomponent'
                );
                if (offsetHeightstickycomponent?.length) {
                    offsetHeightstickycomponent[1].style.top = '0px';
                }
                setTimeout(() => {
                    scrollTitleIntoView('#gallery-product-list', isDesktop, isNative);
                }, 0);
            });
        }
    }, []);

    useEffect(() => {
        /* istanbul ignore next */
        getPageType(window.location.href) === 's' &&
            setExpandOnSearch(
                filterList
                    .map(({ facetValueList = [] }) => facetValueList)
                    .reduce((previous, current) => [...previous, ...current], [])
                    .filter(
                        ({ solrFacetName = '' }) =>
                            !!getURLParameterBykey(solrFacetName, window.location.href, false)
                                .length
                    ).length
            );
    }, [filterList]);

    const facets = useMemo(() => {
        /* istanbul ignore next */
        return filterList.map((facet) => {
            const {
                activeFacet = false,
                facetName = '',
                facetValueList = [],
                noOfSelectedFacetValues = 0,
            } = facet;

            const hasSelected = facetValueList.filter(({ selected }) => selected).length > 0;
            const selectedCount = noOfSelectedFacetValues;
            let header = facetName?.toLowerCase();
            let expanded = false;
            /* istanbul ignore next */
            if (expandOnSearch && (hasSelected || activeFacet)) expanded = true;
            else if (enableMultiSelectFilters) expanded = activeFacet;
            else expanded = hasSelected || activeFacet;

            if (selectedCount > 0) header += ` (${selectedCount})`;

            return {
                expanded,
                header,
                panel: <FilterCategory dataSource={facet} />,
                key: facetName,
            };
        });
    }, [filterList]);

    /* istanbul ignore next */
    const accordionTitleClicked = (event) => {
        const isExpanded = event.target.closest('button[aria-expanded]').ariaExpanded;
        if (
            isExpanded === 'false' &&
            enableMultiSelectFilters &&
            multiSelectedFacetsList &&
            Object.keys(multiSelectedFacetsList).length > 0 &&
            multiFacetUrl
        ) {
            dispatch(
                updateMultiSelectFacetInfo({
                    enableProductsLoader: true,
                    enableFacetLoader: true,
                    scrollToTitle: false,
                })
            );
            multiFacetUrl && updateUrl(multiFacetUrl, null, null, null);
        }
    };
    const filterPanelStyles = dt([
        'bg-white',
        'font-open-sans-regular',
        'h-full',
        'text-black',
        'text-small',
        'scrolling-touch',
    ]);
    const filterPanelDesktopNotTablet = isDesktopView && 'desktop';
    const filterPanelTablet = isTabletView && 'tablet';
    const filterPanelDesktop = dt(['lg:mr-4']);
    const filterPanelSliderStaus = sliderStatus && dt(['overflow-y-auto']);
    const filterPanelNotCase = !enableBrandNameCasing && dt(['lowercase']);
    const filterPanelAccordContainer =
        enableStickyFacet && isDesktopView
            ? `${dt(['overflow-y-auto'])} ${cx('filter-panel-container')}`
            : '';
    if (hasFilters) {
        const filterPanel = () => {
            const offsetHeight = document.getElementById('productlistheight')?.offsetHeight;
            let customStickyStyle = '';
            let setdefaultheight = '';
            let filterHeightClass = '';
            /* istanbul ignore next */
            const updateStickyHeight = (dyHeight) => {
                // fixed hight for minimum facet
                const heightoffSticky = dyHeight ? `height: ${dyHeight}px !important` : '';
                const hasstickyscroll = document.getElementsByClassName('dynamicHeighttoSticky');
                if (hasstickyscroll.length > 0 && hasstickyscroll[1]) {
                    hasstickyscroll[1].setAttribute('style', heightoffSticky);
                }
            };
            /* istanbul ignore next */
            if (enableOverflowFilters && isDesktopView && !__SERVER__) {
                setdefaultheight = dt(['lg:overflow-y-auto']);
                if (offsetHeight && offsetHeight < 1200) {
                    filterHeightClass = 'stickyHeightFixed';
                    // Edge case scenario - based on facet count with one row of product - Facet overlapping with rec zone
                    const productListRow = document.querySelectorAll(
                        'div[data-automation-id="gallery-product-list"]'
                    );
                    if (offsetHeight && offsetHeight > 600 && filterList.length >= 6) {
                        filterHeightClass = '';
                        updateStickyHeight(0);
                    } else if (
                        productListRow?.length === 1 &&
                        filterList.length >= 2 &&
                        offsetHeight &&
                        offsetHeight < 600
                    ) {
                        updateStickyHeight(300); // fixed height
                    }
                    // Edge case scenario - based on facet count with one row of product - Facet overlapping with rec zone
                    customStickyStyle = dt(['lg:!pt-0']);
                    if (window.screen.width <= 1920 && window.screen.height <= 1080) {
                        // const dynamicHeight = offsetHeight < 800 ? offsetHeight + 180 : 0;
                        let dynamicHeight = offsetHeight - 460;
                        if (offsetHeight < 600) {
                            dynamicHeight = offsetHeight - 270;
                        } else if (offsetHeight < 800) {
                            dynamicHeight = offsetHeight - 290;
                        }
                        updateStickyHeight(dynamicHeight);
                    }
                } else {
                    updateStickyHeight(0);
                }
            }

            const accordianFacets = (
                <Accordion
                    automationId={`filterAccordian-${inSlider ? 'filterslider' : 'filterpanel'}`}
                    className={`${cx(
                        'filter-panel-accordion',
                        'scrolling'
                    )}  ${setdefaultheight} ${filterPanelAccordContainer}`} // h-[50rem]
                    onClick={accordionTitleClicked}
                    panelLeftIndent={false}
                    sectionUnderline
                    toggleControlLeft
                    toggleOnlyOne={enableMultiSelectFilters}
                    userToggle
                    isGalleryFilter={!!(hasFilters && isMobileView)}
                    parentSection={inSlider ? 'filterslider' : 'filterpanel'}
                    dynamicAccordionSections
                    fromfacet
                >
                    {facets}
                </Accordion>
            );

            return (
                <div
                    className={` ${cx(
                        'wrapper',
                        filterPanelStyles,
                        filterPanelDesktopNotTablet,
                        filterPanelTablet,
                        filterPanelDesktop,
                        filterPanelSliderStaus,
                        filterPanelNotCase
                    )} stickyScoll ${customStickyStyle}  `}
                    data-automation-id="filter-panel"
                    ref={stickyDiv}
                >
                    <SelectedFacets
                        {...{
                            sliderStatus,
                            wrapperClass: cx('selectedFacets'),
                            inSlider,
                        }}
                    />
                    <StoreFilterCategory
                        {...{
                            customWrapperClasses: `${dt([
                                'border-gray-15',
                                'border-solid',
                                'border-t',
                                'py-4',
                                'px-3',
                                'smOnly:px-0',
                            ])} ${cx({ storeCustomClassTablet: isTabletView })}`,
                            inSlider,
                            sliderStatus,
                            parentSection: `${inSlider ? 'filterslider' : 'filterpanel'}`,
                        }}
                    />
                    {isDesktopView && enableOverflowFilters ? (
                        <div
                            className={` dynamicHeighttoSticky ${cx(
                                'filterStickyScoll',
                                `${filterHeightClass}`
                            )} productHeight-${offsetHeight} `}
                            data-automation-id="filterStickyScoll"
                        >
                            {accordianFacets}
                        </div>
                    ) : (
                        accordianFacets
                    )}
                </div>
            );
        };
        return enableOverflowFilters && isDesktopView ? (
            <StickyBetween
                offSetTopParent={offSetTopParentRef}
                shouleObserveForResize
                isDesktopView
            >
                {filterPanel()}
            </StickyBetween>
        ) : (
            filterPanel()
        );
    }
    if (!hasFilters && countOfSearchResults === 1) {
        return <>1 Result</>;
    }
    return null;
};

FilterPanel.propTypes = {
    inSlider: bool,
};

FilterPanel.defaultProps = {
    inSlider: false,
};

export default FilterPanel;
