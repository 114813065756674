/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { bool, func, object, oneOfType, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import Icon from 'yoda-core-components/lib/components/Icon';
import YdtStars from 'yoda-core-components/lib/assets/svg/YdtStars.svg';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { isDesktopWidth } from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import updateMultiSelectFacetInfo from '../../../../actions/MultiSelectFilterActions';
import { setFailoverCanonicalInfo } from '../../../../actions/GalleryAction';
import styles from './FacetLabel.css';
import { formatPriceRange, updateQueryStringParameter, updateUrl } from '../../../../utils';
import { getMultiSelectedFacetsList, encodedUrl } from '../../../../helpers/FacetHelper';
import { showLoader } from '../../../../actions/LoaderActions';

const cx = classNames.bind(styles);

const FacetLabel = ({ config, facetConfig, large, theme, updateBtnStatus = '' }) => {
    const {
        context: {
            featureFlags: { enableMultiSelectFilters = false, enableCanonicalFallback = true } = {},
            preferences: { multiSelectFacetUpdateTimeout = 50 } = {},
            filters: { filterList = [] } = {},
        } = {},
        multiSelectFilter: { multiSelectedFacetsList = {} } = {},
    } = useSelector((store) => store);
    const isDesktop = isDesktopWidth();
    const dispatch = useDispatch();
    const [multiFacetUrl, setMultiFacetUrl] = useState(null);
    /* istanbul ignore next */
    useEffect(() => {
        const timer =
            multiFacetUrl &&
            setTimeout(
                updateUrl(multiFacetUrl, null, null, {
                    name: config.facetName,
                    value: facetConfig.name,
                    action: facetConfig.selected ? 'remove' : 'add',
                    taxonomyKey: config.facetInternalName
                        ? config.facetInternalName.toLowerCase()
                        : '',
                }),
                multiSelectFacetUpdateTimeout
            );
        return () => clearTimeout(timer);
    }, [multiFacetUrl]);

    const colorFacetChanged = (
        { apiUrl },
        { facetId, facetName, facetInternalName },
        name,
        selected
    ) => {
        const newUrl = updateQueryStringParameter(apiUrl, 'activeFacetId', facetId);
        enableCanonicalFallback &&
            dispatch(
                setFailoverCanonicalInfo({
                    isUpdateCanonicalUrl: true,
                })
            );
        dispatch(showLoader());
        /* istanbul ignore if */
        if (enableMultiSelectFilters) {
            const isChecked = !selected;
            const selectedFacetsList = getMultiSelectedFacetsList(
                config,
                facetConfig,
                filterList,
                isChecked,
                multiSelectedFacetsList
            );
            const updatedMultiFacetUrl = encodedUrl(apiUrl, facetConfig, selectedFacetsList);
            dispatch(
                updateMultiSelectFacetInfo({
                    enableProductsLoader: true,
                    enableFacetLoader: false,
                    multiSelectedFacetsList: selectedFacetsList,
                    multiFacetUrl: updatedMultiFacetUrl,
                    scrollToTitle: false,
                })
            );
            setMultiFacetUrl(updatedMultiFacetUrl);
        } else {
            /* istanbul ignore next */
            updateUrl(newUrl, null, null, {
                action: selected ? 'remove' : 'add',
                name: facetName,
                value: name,
                taxonomyKey: facetInternalName ? facetInternalName.toLowerCase() : '',
            });
        }
        LocalStorage.setData('faceteventtriggered', true); // always true when click event triggered from facets
        updateBtnStatus && updateBtnStatus(false);
    };

    const colorBtnWrapper = (active, name, selected) => {
        const facetLabelBtnStyles = dt([
            'align-middle',
            'border',
            'border-gray-15',
            'border-solid',
            'inline-block',
            'rounded-full',
        ]);
        const facetLabelBtnActiveStyles =
            active && dt(['h-8', 'mr-2', 'relative', 'h-3.5', 'w-3.5']);
        const facetWrapperStyles = dt([
            'h-10',
            'my-0',
            'ml-0',
            'rounded-full',
            'inline-flex',
            'items-center',
            'justify-center',
            'w-10',
        ]);
        const facetWrapperActiveStyles =
            selected && dt(['border-black', 'border-[3px]', 'border-solid', 'p-[3px]']);
        const facetWrapperNotActiveStyles = !selected && dt(['border-transparent']);
        const btnConfig = {
            btnProps: {
                className: cx(
                    'color-pane',
                    `bg-color-${name}`,
                    facetLabelBtnStyles,
                    facetLabelBtnActiveStyles
                ),
                'data-automation-id': 'product-filter-color',
                onClick: () => {
                    colorFacetChanged(facetConfig, config, name, selected);
                },
                style: { backgroundColor: name },
            },
            wrapperProps: {
                className: !active
                    ? cx(facetWrapperStyles, facetWrapperActiveStyles, facetWrapperNotActiveStyles)
                    : null,
            },
        };

        return (
            <span {...btnConfig.wrapperProps}>
                <button {...btnConfig.btnProps} type="button" aria-pressed={!!selected} />
            </span>
        );
    };
    const colorBtnLargeStyles =
        large && `${dt(['relative', 'text-center'])} ${cx('large-wrapper')}`;
    const colorBtnNotLargeStyles =
        !large &&
        dt([
            'inline-flex',
            'flex-col',
            'items-center',
            'mdOnly:w-[101px]',
            'smOnly:w-[87px]',
            'flex',
            'flex-col',
            'items-center',
        ]);
    const colorBtnCountStyles = dt([
        'align-top',
        'capitalize',
        'overflow-hidden',
        'text-black',
        'text-center',
        'pt-1',
    ]);

    const colorBtn = (count, name, selected = false, active = false) =>
        active ? (
            <>
                {colorBtnWrapper(active, name, selected)}
                {name}
            </>
        ) : (
            <div className={cx(colorBtnLargeStyles, colorBtnNotLargeStyles)}>
                {colorBtnWrapper(active, name, selected)}
                <div className={cx(colorBtnCountStyles, 'color-label')}>{`${name} (${count})`}</div>
            </div>
        );

    /* istanbul ignore next */
    const decideFacetContent = ({ facetType }, { count, imageUrl, name, selected, value }) => {
        switch (facetType) {
            case 'checkbox':
                return <span className={theme}>{name}</span>;

            case 'avgrating':
                return count === 0 ? (
                    <span className={theme}>No Ratings</span>
                ) : (
                    <div
                        className={`${dt(['relative', 'align-middle'])} ${cx(theme)}`}
                        style={{ lineHeight: 0 }}
                    >
                        <Icon className={dt(['text-gray-15'])} viewBox="0 0 88 16" width="88">
                            <YdtStars />
                        </Icon>
                        <div
                            className={dt(['absolute', 'overflow-hidden'])}
                            style={{ left: 0, top: 0, width: `${value * 20}%` }}
                        >
                            <Icon className={dt(['text-black'])} viewBox="0 0 88 16" width="88">
                                <YdtStars />
                            </Icon>
                        </div>
                    </div>
                );

            case 'image':
                return (
                    <span className={theme}>
                        <span
                            data-automation-id="image-filter-block"
                            className={dt(['flex', 'content-center', 'overflow-hidden'])}
                        >
                            {imageUrl && (
                                <img
                                    data-automation-id="image-filter-image"
                                    alt={name}
                                    className={dt(['h-5', 'mr-2', 'w-5'])}
                                    src={`/${isDesktop ? 'dotcom' : 'mobile'}/images/${imageUrl}`}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.style.display = 'none';
                                    }}
                                />
                            )}
                            {name}
                        </span>
                    </span>
                );

            case 'prange':
                return <span className={theme}>{formatPriceRange(name)}</span>;

            case 'color':
                return theme === 'default' ? (
                    <span className={`${dt(['inline-block'])} ${cx('color-wrapper')}`}>
                        {colorBtn(count, name, selected)}
                    </span>
                ) : (
                    <div className={`${dt(['inline-block'])} ${cx('color-wrapper-active')}`}>
                        {colorBtn(count, name, selected, true)}
                    </div>
                );

            case 'checkboxImage':
                return (
                    <span className={theme}>
                        {name}
                        {imageUrl && (
                            <img
                                alt={name}
                                className={`${dt(['mr-4'])} ${cx('facet-image')}`}
                                src={`/${isDesktop ? 'dotcom' : 'mobile'}/images/${imageUrl}`}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.style.display = 'none';
                                }}
                            />
                        )}
                    </span>
                );

            default:
                return <span className={theme}>{name}</span>;
        }
    };

    return decideFacetContent(config, facetConfig);
};

FacetLabel.defaultProps = {
    large: false,
};

FacetLabel.propTypes = {
    config: oneOfType([object, func]).isRequired,
    facetConfig: oneOfType([object, func]).isRequired,
    theme: string.isRequired,
    large: bool,
};

export default FacetLabel;
