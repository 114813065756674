import { createSelector } from 'reselect';
import get from 'lodash/get';
import { selectIrisData, selectBreadCrumDataFromSolar } from './fromState';
import { getIrisFallBackStatus } from './IrisFailure';
import {
    irisDataProcessor,
    transformBreadCrumbsInfoIris,
    transformBreadCrumbsInfoSolar,
} from '../utils/irisDataProcesserFunction';

const getIrisBreadCrumbInfo = (state, selfProps) => get(selfProps, 'irisData', undefined);

/*
this selector is to select value from pros irisData example: when irisData is passed to component directly
in case its not passing directly then irisData will procees from stored irisData which is in row and coloumn
format.

selectIrisData : will give irisData from state which is in row format . [row and column format]
getIrisBreadCrumbInfo: data which is directly passed to component this data is alredy formated data.
*/

export const getBreadCrumbFromIrisData = createSelector(
    [selectIrisData, getIrisBreadCrumbInfo],
    (irisDataFromState, irisDataFromProps) => {
        if (irisDataFromProps) {
            return irisDataFromProps;
        }
        if (irisDataFromState) {
            const taxonomyBreadcrumbs = irisDataFromState?.taxonomyData?.breadcrumbsData;
            if (taxonomyBreadcrumbs && taxonomyBreadcrumbs.length >= 2) {
                return { breadcrumbs: taxonomyBreadcrumbs.slice(1) };
            }
            const componentBreadcrumbs = irisDataProcessor(irisDataFromState, 'breadcrumbs');
            if (componentBreadcrumbs) {
                return componentBreadcrumbs;
            }
        }
        return null;
    }
);

/*
this selector is to select value from coreZone in case when data is fetched from search API.
and when data is directly fected from iris.
irisBreadCrumbInfo: data fetching from iris 
*/

const getBreadCrumbInfo = createSelector(
    [getIrisFallBackStatus, selectBreadCrumDataFromSolar, getBreadCrumbFromIrisData],
    (irisFallBackStatus, breadCrumbInfoFromSolar = [], irisBreadCrumbInfo = []) => {
        if (irisFallBackStatus) {
            return transformBreadCrumbsInfoSolar(breadCrumbInfoFromSolar);
        }
        return transformBreadCrumbsInfoIris(irisBreadCrumbInfo);
    }
);

export default getBreadCrumbInfo;
