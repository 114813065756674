export const viewTypes = {
    GRID: 'grid',
    LIST: 'list',
};

export const defaultViewType = viewTypes.GRID;

export const scene7 = {
    url: '//jcpenney.scene7.com/is/image/JCPenney/',
    recipe: '$gallery$',
};

export const scene7desktop = {
    url: '//jcpenney.scene7.com/is/image/JCPenney/',
    recipe: 'wid=350&hei=350&op_usm=.4,.8,0,0&resmode=sharp2',
    recipeVertical: 'wid=400&hei=400&op_usm=.4,.8,0,0&resmode=sharp2',
};

export const scene7Placeholder = {
    url: '//jcpenney.scene7.com/is/image/JCPenney/',
    recipe: 'wid=35&hei=35&op_usm=.4,.8,0,0&resmode=sharp2',
};

export const defaultParam = {};

// Update the below list to send (allowed) qparams to API call

export const filterCategoryPlaceHolder = {
    promotedFilters: 'promotedFilters',
    pillFilters: 'pillFilters',
    // Default placeholder
    filterPanel: 'filterPanel',
};

export const catIdRedirect = {
    url: 'http://m.jcpenney.com/women/dresses/cat.jump?id=cat100210008',
    pattern: new RegExp(/\/cat.jump/i),
    apiPath: 'j/seourl',
};

export const facetIds = {
    storeAvailability: 833,
    sameDayPickup: 832,
};

/*
  1414 and 44682 are facet Ids for Price Range and Discount range filters
  and they are unique and constants so using it to identify price or discount range filters
  key_identifier is key to send.
  identifier: is string to send.
  */
export const rangeIdentifier = {
    1414: {
        key_identifier: 'Nf',
        identifier: 'PR',
        name: 'Price Range',
    },
    44682: {
        key_identifier: 'Dr',
        identifier: 'DR',
        name: 'Percentage Off',
    },
    PriceRangeErrorKey: 'Nf',
    DiscountRangeErrorKey: 'Dr',
};

export const patterns = {
    GALLERY: /\/g(?=\/|\?).*/g,
    SEARCH: /\/s(?=\/|\?).*/g,
    DEPARTMENT: /\/d(?=\/|\?).*/g,
    BRAND: /\/g(?=\/brand|\?).*/g,
    v1patterns: {
        gallery: {
            pattern: '\\/N-*',
            flags: 'g',
        },
        search: {
            pattern: 'Ntt=*',
            flags: 'g',
        },
    },
};
