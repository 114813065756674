import BaseActionFilter from 'yoda-core-components/lib/helpers/Analytics/BaseActionFilter';

import constants from './Analytics.config';
import { getCategoryObj, setsbResultsInfo } from './analytics.helper';
import { getPageType, getLastFilter, getURLParameterBykey } from '../utils';
import { getMultipleThumbnailsPpIds } from '../helpers/GalleryHelper';

/* istanbul ignore next */
class AnalyticsActionFilter extends BaseActionFilter {
    getGalleryPageDL = (store) => {
        const stateObj = store.getState();
        const pagename = getPageType();
        const isGalleryPage = pagename === 'g';
        const nullConstantsPage = isGalleryPage ? constants.PAGE_ng : constants.PAGE_ns;
        const nullConstantsVersion = isGalleryPage ? constants.VERSION_ng : constants.VERSION_ns;
        const enableSponsoredAds = () => {
            const {
                enableSponsoredProduct = false,
                enableCriteo = false,
                enableSponsoredProductNative = true,
            } = stateObj?.context?.featureFlags;

            const isNative = stateObj?.context?.isNative || false;
            const isNativeSponsoredProduct =
                isNative && enableSponsoredProductNative ? true : !isNative;
            const { sponsoredProductsInclusionList = [] } = stateObj?.context?.preferences;
            const pageType = pagename === 'g' ? 'Gallery' : pagename === 's' ? 'Search' : '';
            return (
                enableCriteo &&
                enableSponsoredProduct &&
                sponsoredProductsInclusionList.includes(pageType) &&
                isNativeSponsoredProduct
            );
        };

        const displaySponsoredAds = () => {
            return stateObj?.sponsoredDisplayPage && stateObj?.products?.length > 0 ? 'yes' : 'no';
        };
        const shouldDisplaySponsoredAds = enableSponsoredAds();
        let itemsInSaveForLater = 0;
        let deviceType = '';
        let selectedStores = [];

        const { enableMultipleThumbnails = false } = stateObj?.context?.featureFlags;
        const splitPPIds = stateObj?.products ? getMultipleThumbnailsPpIds(stateObj.products) : [];
        const hasMultipleThumbnails = enableMultipleThumbnails && splitPPIds.length > 0;

        if (stateObj.context.deviceType.isDesktop) {
            deviceType = 'desktop';
        } else {
            deviceType = stateObj.context.deviceType.isMobile ? 'mobile' : 'tablet';
        }
        if (stateObj.list && stateObj.list.savedItems) {
            itemsInSaveForLater = stateObj.list.savedItems.length;
        }
        itemsInSaveForLater += '';
        if (stateObj.bopisInfo && stateObj.bopisInfo.selectedStores) {
            selectedStores = stateObj.bopisInfo.selectedStores;
        }

        const pageType = stateObj.countOfSearchResults
            ? constants[`PAGE_${pagename}`]
            : nullConstantsPage;
        const categoryObj = getCategoryObj(stateObj, pageType);
        const sbResultsInfo = setsbResultsInfo(
            stateObj,
            getLastFilter(),
            selectedStores,
            '',
            shouldDisplaySponsoredAds,
            hasMultipleThumbnails
        );
        const enableDiscoveryEngineTracking =
            stateObj.context.featureFlags?.enableDiscoveryEngineTracking ?? true;
        const isV3Page = stateObj?.commonData?.requestUrl?.indexOf('/v3/');
        const discoveryEngine =
            enableDiscoveryEngineTracking &&
            isGalleryPage &&
            isV3Page !== undefined &&
            isV3Page !== -1
                ? { discoveryEngine: stateObj?.commonData?.discoveryEngine || '' }
                : {};

        /* istanbul ignore next */
        if (window && window.digitalData && window.digitalData.product) {
            delete window.digitalData.product;
        }
        /* istanbul ignore next */
        const galleryPageDL = {
            page: {
                category: categoryObj,
                pageInfo: {
                    ...(shouldDisplaySponsoredAds && {
                        shouldDisplaySponsoredAds: displaySponsoredAds(),
                    }),
                    hasMultipleThumbnails,
                    splitPPIds:
                        enableMultipleThumbnails && splitPPIds?.length > 0 ? splitPPIds : 'n/a',
                    pageType,
                    siteSection: categoryObj.primaryCategory,
                    searchRedirect: getURLParameterBykey('redirectTerm') || '',
                    deviceType,
                    releaseVersion: stateObj.context.__RELEASEVERSION__, // eslint-disable-line
                    version: stateObj.countOfSearchResults
                        ? `apollo 1 ${pageType}`
                        : nullConstantsVersion,
                    ...discoveryEngine,
                },
                sbResultsInfo,
            },
            user: {
                // this will be common for all Micro pages- But we reading info from store, should be there in each micro site
                profile: {
                    profileInfo: {
                        itemsInSaveForLater,
                    },
                },
            },
        };

        if (deviceType !== 'desktop') {
            galleryPageDL.page.pageInfo.pageOrientation =
                Math.abs(window.orientation) === 90 ? 'landscape' : 'portrait';
        }

        return galleryPageDL;
    };

    getDynamicGalleriesItemClick = (payload) => {
        return {
            dynamicGalleryID: payload.dynamicGalleryID,
            dynamicGalleryPPID: payload.dynamicGalleryPPID,
            moduleName: payload.moduleName,
        };
    };

    getDynamicGalleriesLoadSuccess = (payload = {}) => {
        if (payload.page && payload.page.attributes) {
            payload.page.attributes = {
                ...payload.page.attributes,
                dynamicGalleryModule: 'yes',
            };
        }
        return payload;
    };

    getDynamicGalleriesModuleClick = (payload = {}) => {
        return { dynamicGalleryID: payload.dynamicGalleryID };
    };

    saveProductData = (payload) => {
        return {
            listType: 'my fav',
            product: [
                {
                    productInfo: {
                        productPPID: payload[0].product.id,
                    },
                },
            ],
        };
    };

    getProductFilters = (
        store,
        lastFilter,
        promotedFilter,
        selectedStores,
        pageLoad,
        selectedFilters
    ) => {
        let typeOfFilter;
        const { value = '' } = promotedFilter;
        const promotedAttrs = promotedFilter && value !== '';
        const filterType = {
            curated: 'curated',
            autosuggestion: 'auto-suggestion',
            leftNav: 'left nav',
            pill: 'pill',
            promoted: 'promoted',
            interactivevisnav: 'interactivevisnav',
        };

        if (promotedAttrs && value.includes(filterType.interactivevisnav)) {
            typeOfFilter = filterType.interactivevisnav;
        } else if (!lastFilter && selectedFilters) {
            const pagename = getPageType();
            const itemType = getURLParameterBykey('item_type');
            typeOfFilter =
                pagename === 's' && itemType ? filterType.autosuggestion : filterType.curated;
        } else if (promotedAttrs && !value.includes('pill')) {
            typeOfFilter = filterType.promoted;
        } else if (promotedAttrs && value.includes('pill')) {
            typeOfFilter = filterType.pill;
        } else {
            typeOfFilter = filterType.leftNav;
        }

        return {
            page: {
                sbResultsInfo: {
                    ...setsbResultsInfo(store.getState(), lastFilter, selectedStores, pageLoad),
                    typeOfFilter,
                },
            },
        };
    };

    /* istanbul ignore next */
    getSwitchPageOrientationData = (viewType) => {
        return {
            page: {
                pageInfo: {
                    eventAction: (viewType || '').toLowerCase(),
                },
            },
        };
    };

    getGalleryVideoData = (videoData = {}) => {
        return {
            page: {
                pageInfo: {
                    videoPlayer: videoData.videoPlayerData,
                },
            },
            product: videoData.product,
        };
    };
}

export default AnalyticsActionFilter;
