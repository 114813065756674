/* eslint-disable import/no-named-as-default */
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { array, object, oneOfType, string, bool } from 'prop-types';
import classNames from 'classnames/bind';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import List from 'yoda-core-components/lib/components/List/List';
import renderCurrentComponent from 'yoda-site-components/lib/components/IrisPage/renderCurrentComponent';
import { isMobileWidth, mobileQuery } from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import { viewTypes } from '../../configurations/config';
import * as styles from './ProductList.css';
import GalleryProductCard from '../GalleryProductCard/GalleryProductCard';
import ProductMarketingTile from '../ProductMarketingTile/ProductMarketingTile';
import ProductsLoader from './ProductsLoader';
import ScrollRendererWrapper from '../ScrollRendererWrapper/ScrollRendererWrapper';
import ProductSkeleton from './ProductSkeleton';

const cx = classNames.bind(styles);

/* istanbul ignore next */
const ProductListRenderer = ({
    dataSource: { adMonetization, productList },
    gridAds,
    irisComponents,
    irisComponentsWithoutGrid,
    irisPropsData,
    marketingSlot,
    renderBrPreviewSkeletonGrid,
    enableVerticalImages,
    enableVerticalImageLg,
}) => {
    const state = useSelector((reduxState) => reduxState);
    const {
        context: {
            deviceType: { isMobile = false },
            featureFlags: { enableFourThumbnailsGrid = false },
        } = {},
        productSettings: { isFacetOpened = false, viewType = 'grid' } = {},
    } = state;

    const [custStyle4thumbnail, setCustStyle4thumbnail] = useState('');

    const handleResize = (ismobile) => {
        if (ismobile) {
            setCustStyle4thumbnail('w-1/2');
        } else if (!ismobile && isFacetOpened && enableFourThumbnailsGrid) {
            setCustStyle4thumbnail('w-1/4');
        } else if (!ismobile && isFacetOpened) {
            setCustStyle4thumbnail('w-1/3');
        }
    };

    useEffect(() => {
        handleResize(isMobileWidth());

        const mQuery = window.matchMedia(mobileQuery);
        /* istanbul ignore next */
        mQuery.onchange = (e) => handleResize(e?.matches);
    }, []);

    const renderChildComponent = (product) => {
        const {
            enableLoader = false,
            multiSelectFilter: { enableProductsLoader = false } = {},
            context: { deviceType = {} } = {},
        } = state;

        if (renderBrPreviewSkeletonGrid && product?.name === 'Bloomreach mock product') {
            return (
                <ProductSkeleton
                    className={dt(['sm:px-1', 'md:px-2', 'sm:before:!animate-none'])}
                />
            );
        }

        if (enableProductsLoader && enableLoader) {
            return <ProductsLoader hasProducts />;
        }

        if (product.marketingSlot && viewType !== viewTypes.List) {
            return (
                <ScrollRendererWrapper>
                    <ProductMarketingTile
                        deviceType={deviceType}
                        disableLoader
                        irisComponents={irisComponents}
                        irisComponentsWithoutGrid={irisComponentsWithoutGrid}
                        irisPropsData={irisPropsData}
                        marketingSlot={marketingSlot}
                        productMarketingSlot={product.marketingSlot}
                        slotPosition={product.marketingSlot.slotPosition}
                    />
                </ScrollRendererWrapper>
            );
        }

        return (
            <ScrollRendererWrapper
                loader={<ProductSkeleton className={dt(['sm:px-1', 'md:px-2'])} />}
            >
                <GalleryProductCard
                    cardType={viewType}
                    fromProductList
                    index={product.index}
                    productDetails={product}
                    showVerticalProductImage={enableVerticalImages}
                    enableVerticalImageLg={enableVerticalImageLg}
                />
            </ScrollRendererWrapper>
        );
    };

    return (
        <>
            {productList ? (
                <MemoizedList
                    automationId="gallery-product-list"
                    childRenderer={(product) => renderChildComponent(product)}
                    datasource={productList}
                    direction="Wrap"
                    itemStyleClass={`${cx('list-item')} ${dt([
                        'mb-6',
                        isMobile && 'w-1/2',
                        custStyle4thumbnail,
                        viewType === viewTypes.LIST && 'w-full',
                    ])}`}
                    listBodyClass={cx('list-container')}
                    listStyleClass={`${dt(['flex', 'flex-wrap'])} ${cx('list')}`}
                    spacing="None"
                    listMainCustomClass={cx('gallery-list')}
                />
            ) : (
                adMonetization &&
                renderCurrentComponent(
                    gridAds,
                    0,
                    irisComponents,
                    irisComponentsWithoutGrid,
                    irisPropsData
                )
            )}
        </>
    );
};

const MemoizedList = memo(List);

ProductListRenderer.defaultProps = {
    gridAds: {},
    irisComponents: {},
    irisComponentsWithoutGrid: {},
    irisPropsData: {},
    marketingSlot: {},
    renderBrPreviewSkeletonGrid: false,
    enableVerticalImages: false,
    enableVerticalImageLg: false,
};

ProductListRenderer.propTypes = {
    dataSource: oneOfType([array, object]).isRequired,
    gridAds: oneOfType([object, string]),
    irisComponents: oneOfType([array, object]),
    irisComponentsWithoutGrid: oneOfType([array, object]),
    irisPropsData: oneOfType([array, object]),
    marketingSlot: oneOfType([array, object]),
    renderBrPreviewSkeletonGrid: bool,
    enableVerticalImages: bool,
    enableVerticalImageLg: bool,
};

export default ProductListRenderer;
