/* eslint-disable no-restricted-globals */
/* istanbul ignore file */
import getBrowserHistory from 'yoda-core-components/lib/navigation/history/getBrowserHistory';
import each from 'lodash/each';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import merge from 'lodash/merge';
import split from 'lodash/split';
import RecentSearch from 'yoda-site-components/lib/components/ProductSearch/RecentSearch';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import RestClient from 'yoda-interfaces/lib/RestClient/RestClient';
import { getLocaleString } from 'yoda-site-components/lib/components/Pricing/PricingHelper';
import {
    defaultParam,
    facetIds,
    patterns,
    rangeIdentifier,
    scene7,
    scene7desktop,
} from '../configurations/config';

let filterAnalytics = true;
let lastFilter;
let orderedStoreSeq = '';
let pageLoad = true;
let prevNtt;
let prevPage;
let prevUrl;

/**
 *
 * @param {string} url
 * Method to identify the URL is absolute or relative
 */
export const isAbsoluteUrl = (url) => {
    const absoluteUrlPattern = /^https?:\/\//i;
    return absoluteUrlPattern.test(url);
};

export const getDeviceType = (deviceType) => {
    let type = '';
    if (deviceType.isDesktop) {
        type = 'desktop';
    } else {
        type = deviceType.isMobile ? 'mobile' : 'tablet';
    }
    return type;
};

export const getContentDate = () => Cookies.load('DP_PREVIEW_DATE');

export const getPriceFormatted = (price, enableBundlePriceFix = true) => {
    const originalPriceLabel = price.originalPriceLabel ? price.originalPriceLabel : 'original';
    let currentPriceLabel;
    if (enableBundlePriceFix) {
        currentPriceLabel =
            !price.currentPriceLabel || price.currentPriceLabel.toUpperCase() === 'ORIGINAL'
                ? 'DEFAULT'
                : price.currentPriceLabel;
    } else {
        currentPriceLabel =
            price.currentPriceLabel === 'original' || !price.currentPriceLabel
                ? 'DEFAULT'
                : price.currentPriceLabel;
    }

    const schemeWithPrice = {
        amounts: [
            {
                max: price.originalMax || 0,
                min: price.originalMin || 0,
                type: originalPriceLabel || '',
                minPercentOff: 0,
                maxPercentOff: 0,
            },
            {
                max: price.currentMax || 0,
                min: price.currentMin || 0,
                type: currentPriceLabel || '',
                minPercentOff: price.minSavePrice || 0,
                maxPercentOff: price.maxSavePrice || 0,
            },
        ],
        manufacturerAdvertised: price.mapPrice,
        marketingLabel: price.marketingLabel,
        promotions: price.promotionMessages || [],
    };

    if (price.fpacPriceMax && price.fpacPriceMin) {
        schemeWithPrice.fpac = {
            fpacPriceMax: price.fpacPriceMax,
            fpacPriceMin: price.fpacPriceMin,
            fpacCoupon: price.fpacCoupon || '',
            brand: price.brand || '',
            subDivision: price.subDivision || '',
        };
    }

    return schemeWithPrice;
};

export const getScene7ImageURL = (url) =>
    isAbsoluteUrl(url) ? url : `${scene7.url}${url}?${scene7.recipe}`;

export const getScene7desktopImageURL = (url, verticalImage, enableVerticalImageLg) =>
    isAbsoluteUrl(url)
        ? url
        : `${scene7desktop.url}${url}?${
              verticalImage && enableVerticalImageLg
                  ? scene7desktop.recipeVertical
                  : scene7desktop.recipe
          }`;

export const getPageURL = (fullUrl) => {
    let url = '';
    if (!__SERVER__) {
        if (fullUrl) {
            url = location.href;
        } else {
            url = location.pathname + location.search;
        }
    }
    return url;
};

export const getURLParameterBykey = (key, url = getPageURL(), removeSplChars) => {
    // TODO - this will moved to Util function later
    let name = key;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    let results = regex.exec(url);
    if (!results) return '';
    if (!results[2]) return '';
    results = decodeURIComponent(results[2]);
    return removeSplChars ? results.replace(/['+\s]/g, '') : results.replace(/\+/g, ' ');
};

export const updateQueryStringParameter = (uri = getPageURL(), key, value) => {
    if (value) {
        const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
        const separator = uri.indexOf('?') !== -1 ? '&' : '?';
        if (uri.match(re)) {
            return uri.replace(re, `$1${key}=${value}$2`);
        }
        return `${uri}${separator}${key}=${value}`;
    }
    return uri;
};

export const removeQueryStringParameter = (uri = getPageURL(), key) => {
    const regExp = new RegExp(`\\b${key}=[^&]+`, 'i');
    return uri
        .replace(regExp, '')
        .replace('?&', '?')
        .replace('&&', '&')
        .replace(/(\?$)|(&$)/, '');
};

export const getPageType = (url) => {
    let pageType = null;
    let urlValue = url;
    urlValue = url || (!__SERVER__ && location.href);
    const gPatt = new RegExp(patterns.GALLERY);
    const sPatt = new RegExp(patterns.SEARCH);
    const dPatt = new RegExp(patterns.DEPARTMENT);
    if (gPatt.test(urlValue)) {
        pageType = 'g';
    } else if (sPatt.test(urlValue)) {
        pageType = 's';
    } else if (dPatt.test(urlValue)) {
        pageType = 'd';
    }
    return pageType;
};

export const isBrandPage = (url) => {
    let urlValue = url;
    urlValue = url || (!__SERVER__ && location.href);
    const brandPatt = new RegExp(patterns.BRAND);
    if (brandPatt.test(urlValue)) {
        return true;
    }
    return false;
};

/**
 * Return's NID value.
 * @param {*} url - "/g/mens-shirts/N-bwo3yD1nohp5"
 * return - bwo3yD1nohp5
 */
export const getCategoryNID = (url) => split(url || (!__SERVER__ && location.pathname), '/N-')[1];

export const getPageURLFromAPIURL = (apiUrl = '') => {
    let pageUrl;
    if (getPageType(apiUrl) === 'g') {
        const galleryPage = patterns.GALLERY;
        const newURL = apiUrl.match(galleryPage);
        pageUrl = newURL && newURL[0];
    } else if (getPageType(apiUrl) === 's') {
        const searchPage = patterns.SEARCH;
        const newURL = apiUrl.match(searchPage);
        pageUrl = newURL && newURL[0];
    } else {
        pageUrl = apiUrl;
    }

    if (pageUrl) {
        // pageUrl = pageUrl;
        return `${pageUrl}`;
    }
    return '/';
};

export const getRandomId = () =>
    `check${(new Date().getTime() * Math.random()).toString().substr(0, 7)}`;

/**
 * @description
 * Adds the valid params to the url
 * Removes the invalid params from the url
 */
export const optimizeParams = (url, params = {}) => {
    let urlValue = url;
    each(merge({}, defaultParam, params), (value, key) => {
        if (value) {
            urlValue = updateQueryStringParameter(urlValue, key, value);
        } else {
            urlValue = removeQueryStringParameter(urlValue, key);
        }
    });
    return urlValue;
};

/**
 * @description
 * Generates the new url by combining the qparams of canonical url + current url + next qparams
 */
export const getNewUrl = (url, params) => {
    let newUrl = optimizeParams(url, params);
    const retainParams = ['view', 'UsrSeg'];

    if (url && includes(location.search, '?')) {
        retainParams.forEach((currentParam) => {
            const actualVal = getURLParameterBykey(currentParam, location.search);
            if (actualVal) {
                newUrl = updateQueryStringParameter(newUrl, currentParam, actualVal);
            }
        });
    }

    return newUrl;
};

export const redirectToUrl = (url, replace, params) => {
    let urlValue = url;
    if (!__SERVER__ && !Cookies.load('forceStopRedirects')) {
        urlValue = params ? getNewUrl(url, params) : url;
        if (replace) {
            location.replace(urlValue);
        } else {
            location.href = urlValue;
        }
    }
};

export const getPageTypeUniqueUrl = (url = '') => {
    if (getPageType(url) === 'g') return (url.split('?')[0].split('N-')[1] || '').split('Z')[0];
    return getURLParameterBykey('Ntt', url, true);
};

export const compareUrls = (sourceUrl = '', targetUrl = getPageURL(), newPageCheck) => {
    const context = RestClient.getContext();
    let returnUrl = targetUrl;
    if (context.hostname === 'preview.jcpenney.com' && targetUrl) {
        returnUrl = targetUrl.replace(/preview\//i, '');
    }
    if (newPageCheck) {
        return getPageTypeUniqueUrl(sourceUrl) === getPageTypeUniqueUrl(returnUrl);
    }
    return split(sourceUrl, '?')[0] === split(returnUrl, '?')[0];
};

/**
 * @description
 * Updates the browser url
 */
export const goUrl = (newUrl, replace, lastFilterData) => {
    if (getPageType(newUrl) === 'd') {
        redirectToUrl(newUrl);
    } else if (replace) {
        // Replace browser history
        getBrowserHistory() && getBrowserHistory().replace(newUrl);
    } else {
        // Add to browser history
        getBrowserHistory().push(newUrl);
        lastFilter = lastFilterData;
    }
};

/**
 * @description
 * constructs the browser url
 */
export const updateUrl = (nextUrl, nextParams, replace, lastFilterData) => {
    if (includes(nextUrl, 'pageType=X2H2')) {
        // If we already know it's a departments page, lets do a fast redirect
        redirectToUrl(getPageURLFromAPIURL(nextUrl));
    } else {
        const pageUrl = nextUrl ? getPageURLFromAPIURL(nextUrl) : undefined;
        let newUrl = getNewUrl(pageUrl, nextParams);
        if (!(nextParams && nextParams.scrollTo)) {
            newUrl = removeQueryStringParameter(newUrl, 'scrollTo');
        }
        if (newUrl && nextParams) {
            const splitUrl = newUrl.split('&');
            let keyToBeRemoved;
            if (nextParams.Nf) {
                keyToBeRemoved =
                    splitUrl.filter((data) =>
                        data.includes(rangeIdentifier.DiscountRangeErrorKey)
                    ) && rangeIdentifier.DiscountRangeErrorKey;
            } else if (nextParams.Dr) {
                keyToBeRemoved =
                    splitUrl.filter((data) => data.includes(rangeIdentifier.PriceRangeErrorKey)) &&
                    rangeIdentifier.PriceRangeErrorKey;
            }
            newUrl = keyToBeRemoved ? removeQueryStringParameter(newUrl, keyToBeRemoved) : newUrl;
        }
        // Finally apply the newly constructed url, which has the optimized qparams
        goUrl(newUrl, replace, lastFilterData);
    }
};

export const getLastFilter = () => lastFilter;

export const isFilterAnalytics = () => filterAnalytics;

export const disableFilterAnalytics = () => {
    filterAnalytics = false;
};

export const enableFilterAnalytics = () => {
    filterAnalytics = true;
};

export const isFirstPage = () => {
    const curPage = getURLParameterBykey('page');
    if (prevPage !== curPage && (curPage === '' || curPage === '1')) {
        prevPage = curPage;
        return true;
    }
    prevPage = curPage;
    return false;
};

export const isFirstPageWithoutPrev = (url) => {
    const curPage = getURLParameterBykey('page', url);
    if (curPage === '' || curPage === '1') {
        return true;
    }
    return false;
};

export const isHydrated = (props) => {
    const canonical = get(props.commonData, 'seoTitleTags.canonicalURL');
    const forceRefresh = Cookies.load('callOnClient');
    if (
        !forceRefresh &&
        get(props.commonData, 'hasProductData') &&
        get(props.commonData, 'gallery')
    ) {
        // On server side rendering if canonicalURL is different then updated the URL.
        if (!__SERVER__ && canonical && !compareUrls(canonical, props.location.pathname)) {
            updateUrl(canonical, {}, true);
        }
        return true;
    }
    return false;
};

export const isContentHydrated = (props) => {
    const forceRefresh = Cookies.load('callOnClient');
    if (!forceRefresh && !isEmpty(props.coreZones)) {
        return true;
    }
    return false;
};

export const isContentHydratedWithIris = (irisData) => {
    const forceRefresh = Cookies.load('callOnClient');
    if (!forceRefresh && !isEmpty(irisData)) {
        return true;
    }
    return false;
};

export const getSearchTermFromUrl = () =>
    getURLParameterBykey('Ntt') || getURLParameterBykey('searchTerm');

export const addToRecentSearch = (keyword) => {
    if (!getURLParameterBykey('searchType').match(/related/i)) {
        try {
            RecentSearch.setData(keyword || getSearchTermFromUrl());
        } catch (e) {
            // Error
        }
    }
};

export const isControlsEnabled = () =>
    getURLParameterBykey('enableControls') || Cookies.load('enableControls');

export const updateStoresToUrl = (
    facetConfig = {},
    storesList = [],
    isSingleSelect = false,
    enableMultiSelectFilters = false,
    multiFacetUrl = ''
    // eslint-disable-next-line consistent-return
) => {
    let updatedUrl;
    let nextUrl;
    const storeIds = [...getURLParameterBykey('storeIds').split('-')];
    const storeIndex = storeIds.indexOf(facetConfig.storeId);

    if (facetConfig) {
        nextUrl =
            facetConfig.selected || facetConfig.selectDefaultStore ? nextUrl : facetConfig.apiUrl;
    }

    nextUrl = enableMultiSelectFilters && multiFacetUrl ? multiFacetUrl : nextUrl;

    if (facetConfig.action && storeIndex < 0) storeIds.push(facetConfig.storeId);
    else if (storeIndex >= 0) storeIds.splice(storeIndex, 1);

    if (storeIds.length) {
        // Sorting store Ids based on distance.
        const sortedList = [];

        each(storesList, (store) => {
            if (storeIds.indexOf(store.id) >= 0) {
                sortedList.push(store.id);
            }

            return !(sortedList.length === storeIds.length);
        });

        updatedUrl = updateQueryStringParameter(
            nextUrl,
            'storeIds',
            isSingleSelect ? facetConfig.storeId : sortedList.join('-')
        );
    } else {
        nextUrl = enableMultiSelectFilters && multiFacetUrl ? multiFacetUrl : facetConfig.apiUrl;
        updatedUrl = removeQueryStringParameter(nextUrl, 'storeIds');
    }

    if (enableMultiSelectFilters) return updatedUrl;

    const taxonomyKey =
        facetConfig.name === 'same day pickup' ? facetConfig.name : facetConfig.facetInternalName;

    updateUrl(updatedUrl, null, null, {
        name: facetConfig.name,
        value: facetConfig.value,
        action: facetConfig.action ? 'add' : 'remove',
        taxonomyKey: taxonomyKey ? taxonomyKey.toLowerCase() : '',
    });
};

/**
 * @description
 * Formats the Price Range
 * Eg: $1000 - $1099 -> $1,000 - $1,099
 */
export const formatPriceRange = (value) => {
    const priceRangeRegExp = new RegExp(/(.*\$)([0-9]+)(.*\$)([0-9]+)(.*)|(.*\$)([0-9]+)(.*)/);

    if (priceRangeRegExp.test(value)) {
        return value.replace(priceRangeRegExp, ($0, $1, $2, $3, $4, $5, $6, $7, $8) => {
            if ($1) {
                // price ranges like $1000 - $1099
                return `${$1}${getLocaleString($2)}${$3}${getLocaleString($4)}${$5}`;
            }
            // price ranges like under $1000, over $4999
            return `${$6}${getLocaleString($7)}${$8}`;
        });
    }
    return value;
};

export const removeStoreIdsFromUrl = (url, config) => {
    // If store availability filter(facetId is 833) is unchecked then remove dependent storeIds attribute
    // When user deselect StoreAvailability from FilterChips then (config.facetValId === facetIds.sameDayPickup) will be true and remove storeIds.
    const storeIds = getURLParameterBykey('storeIds');
    let urlValue = url;
    if (
        storeIds &&
        config &&
        (config.facetId === facetIds.storeAvailability ||
            config.facetValId === facetIds.sameDayPickup)
    ) {
        urlValue = removeQueryStringParameter(url, 'storeIds');
    }
    return urlValue;
};

export const parseRequestUrl = (request, location) => {
    const protocol = 'https:';
    const origin = `${protocol}//${request.hostName}`;
    // updated to mitigate veracode flaw so far observed as we not using this please decode if anywhere its been used
    const url = `${origin}${encodeURIComponent(location.pathname)}${encodeURIComponent(
        location.search
    )}`;
    return {
        protocol,
        origin,
        href: url,
        isMobile: request.hostName && !!request.hostName.match(/^m(.*).jcpenney/i),
    };
};

/**
 * @description
 * Fetches UsrSeg values depending on platform
 * @param abTestEngagements - [
 *  {"testNumber":"JAB639",
 *      "channel":"DT",
 *      "testType":"GalleryHelios",
 *      "platform":"SOLR1",
 *      "userSegments":"",
 *      "abTest":"JAB639:DT:GalleryHelios:SOLR1"}]
 * return { UsrSeg: 'd7obcu' }
 */
export const getABTestingUserSeg = (abTestEngagements) => {
    const abTestSegment = find(abTestEngagements, (obj) => obj.testType === 'USRSEG');
    const ABTestingParams = {};
    if (abTestSegment && abTestSegment.platform) {
        ABTestingParams.UsrSeg = abTestSegment.platform;
    }
    return ABTestingParams;
};

export const isClientSideRenderingEnabled = (featureFlags) =>
    get(featureFlags, 'enableRealTimeInventory', false);

export const parseURL = (url) => {
    const l = document.createElement('a');
    l.href = url;
    return l;
};

export const isPageLoad = () => pageLoad;

export const feedPageLoad = () => {
    pageLoad = false;
    return false;
};

export const getCriteoPageID = (page, deviceType) => {
    let origin = '';
    if (page === 'gallery') {
        origin = deviceType.isDesktop ? 'Browse_Web' : 'Browse_Mobile';
    } else {
        origin = deviceType.isDesktop ? 'Search_Web' : 'Search_Mobile';
    }
    return origin;
};

export const getProductsPerPage = (preferences) => get(preferences, 'productsPerPage', 24);

export const showHorizonalFilters = (abTestEngagements, deviceType = {}) => {
    const abTestSegment = find(abTestEngagements, (obj) => obj.testType === 'HORIZONTAL_FILTERS');
    // TODO: Remove URL param check
    if (
        deviceType.isMobile &&
        ((abTestSegment && abTestSegment.platform === 'true') ||
            getURLParameterBykey('horizontalFilters') === 'true')
    ) {
        return true;
    }
    return false;
};

export const getParams = (query, decodePlus = true) => {
    if (!query) {
        return {};
    }

    return (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        const paramsValue = params;
        const [key, value] = param.split('=');
        if (value) {
            paramsValue[key] = decodePlus ? decodeURIComponent(value.replace(/\+/g, ' ')) : value;
        } else {
            paramsValue[key] = '';
        }
        return paramsValue;
    }, {});
};

export const formatLocationServiceData = (data = {}) => {
    const { storeId: id, storeName: name, distance, zipcode: zip, curbsidePickupIndicator } = data;
    return id
        ? {
              id,
              name,
              distance,
              zip,
              curbsidePickupIndicator,
          }
        : null;
};

export const getLabelZoneDataInFormat = (products) => {
    if (products.length) {
        return map(products, (product) => ({
            ...product,
            id: product.ppId,
            name: product.name,
            linkURL: product.pdpUrl,
            imageURL: getScene7ImageURL((product.imagesInfo || {}).thumbnailImageId),
            rating: product.averageRating,
            reviews: product.reviewCount,
            priceDetails: getPriceFormatted(product),
        }));
    }
    return [];
};

// Memoization:  Sorted list of stores
export const setStoreSeq = (storeSeq) => {
    orderedStoreSeq = storeSeq;
};

export const getStoreSeq = () => orderedStoreSeq;
