import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { array, object, oneOfType } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import ScriptLoader from 'yoda-core-components/lib/helpers/ScriptLoader/ScriptLoader';
import { miniPDPOnDynamicGalleriesOpen } from 'yoda-site-components/lib/actions/MiniPDPAction';
import {
    dynamicGalleriesItemClick,
    dynamicGalleriesLoadSuccess,
    dynamicGalleriesModuleClick,
} from '../../actions/AnalyticsActions';
import { getMappedStyliticsURL, getProductColorwayIDs } from '../../helpers/GalleryHelper';

const DynamicGalleriesWidget = ({ actions, products }) => {
    const {
        context: {
            featureFlags: { enableFallbackIDs = true } = {},
            messagesTexts: {
                dynamicGalleriesWidget: { cardFooterText = '', promoCodeText = '' } = {},
            } = {},
            preferences: { dynamicGalleriesWidget: { scriptURL = '' } = {} } = {},
            requestUrl = '',
        } = {},
        productList: { galleryDetails: { breadCrumbInfo = [] } = {} } = {},
        miniPDP = {},
    } = useSelector((store) => store);
    const prevProps = useRef(null);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [hasContent, setHasContent] = useState(false);
    const catId = breadCrumbInfo[1]?.catId || '';
    const DYNAMIC_GALLERIES_WIDGET_CONTAINER_ID = 'dynamic-galleries-widget-container';
    /* istanbul ignore next */
    if (hasContent) {
        window && actions.dynamicGalleriesLoadSuccess(window.digitalData);
    }

    /* istanbul ignore next */
    const onDynamicGalleriesItemClick = (item, outfit) => {
        const skuData = item?.remote_id || '';
        const itemId = item?.item_id || '';
        const skuID = item?.other_client_item_ids?.sku_nbrs[0] || '';
        /* istanbul ignore next */
        if (!isEmpty(skuData)) {
            const options = {
                ppId: skuData.split('_')[0],
                skuId: skuID,
            };
            const customEvent = document.createEvent('CustomEvent');
            customEvent.initCustomEvent('miniPDP.open', true, true, options);
            document.dispatchEvent(customEvent);
            actions.miniPDPOnDynamicGalleriesOpen(itemId);
            actions.dynamicGalleriesItemClick &&
                actions.dynamicGalleriesItemClick({
                    dynamicGalleryID: outfit?.id?.toString() || '',
                    dynamicGalleryPPID: options.ppId,
                    moduleName: 'dynamic gallery',
                });
        }
    };

    /* istanbul ignore next */
    const widgetOptions = {
        src: scriptURL,
        async: true,
        defer: false,
        onSuccess: () => {
            const dynamicGalleriesWidget = new StyliticsDynamicGalleriesWidget(
                'jcpenney',
                DYNAMIC_GALLERIES_WIDGET_CONTAINER_ID,
                {
                    api: {
                        min: 3,
                        max: 6,
                        page: getMappedStyliticsURL(catId, requestUrl),
                        ids: enableFallbackIDs ? getProductColorwayIDs(products) : '',
                    },
                    text: {
                        viewItemsCTAText: cardFooterText,
                    },
                }
            );

            setIsScriptLoaded(true);
            dynamicGalleriesWidget.on('mount', 'outfits', ({ outfits }) => {
                if (outfits?.length > 0) {
                    setHasContent(true);
                }
            });
            dynamicGalleriesWidget.on('click', 'bundle', ({ bundle }) => {
                actions.dynamicGalleriesModuleClick({
                    dynamicGalleryID: bundle?.id?.toString() || '',
                });
            });
            dynamicGalleriesWidget.on('mount', 'item', ({ item }) => {
                if (item.promotions.includes('with_code')) {
                    const text = document.createElement('span');
                    text.classList.add('promotional-text');
                    text.innerHTML = promoCodeText;
                    const priceContainer = document.querySelectorAll('.stylitics-ui-price');
                    priceContainer.forEach((price, i) => {
                        if (i === item.index) {
                            const promotionalPrice = price.querySelectorAll(
                                '.stylitics-ui-sale-price'
                            )[0];
                            promotionalPrice.append(text);
                            promotionalPrice.classList.add('promotional-sale-price');
                        }
                    });
                }
            });
            dynamicGalleriesWidget.override('click', 'item', ({ item, outfit }) => {
                onDynamicGalleriesItemClick(item, outfit);
            });
            dynamicGalleriesWidget.start();
        },
    };

    const stylisticsMarkup = () => {
        const stylistics = `<style>
        .stylitics-ui-carousel-outer {
            font-family: "Open Sans" !important;
        }
        .stylitics-context-action {
            color: #666666 !important;
            line-height: 20px !important;
        }
        .stylitics-context-item {
            color: #202020 !important;
            font-weight: 600 !important;
            line-height: 20px !important;
        }
        .stylitics-dynamic-galleries-card-footer {
            color: #202020 !important;
            font-size: 16px !important;
            font-weight: 700 !important;
            line-height: 24px !important;
        }
        .stylitics-dynamic-galleries-modal-heading {
            color: #202020 !important;
            font-size: 16px !important;
            font-weight: 600 !important;
            line-height: 24px !important;
        }
        .stylitics-ui-item-info-details {
            color: #202020 !important;
            font-family: "Arial" !important;
            font-weight: 400 !important;
        }
        .stylitics-item-regular-price {
            color: #202020 !important;
            font-family: "Arial" !important;
            font-size: 14px !important;
            font-weight: 700 !important;
        }
        .stylitics-item-regular-price-slashed {
            color: #666666 !important;
            font-family: "Arial" !important;
            font-size: 14px !important;
        }
        .stylitics-item-sale-price {
            font-family: "Arial" !important;
            font-size: 14px !important;
            font-weight: 700 !important;
        }
        .promotional-sale-price {
            color: #E71324 !important;
            font-family: "Arial" !important;
            font-size: 14px !important;
            font-weight: 700 !important;
        }
        .promotional-text {
            font-style: italic !important;
            font-weight: 400 !important;
        }
        </style>`;
        return stylistics;
    };

    useEffect(() => {
        if (!isScriptLoaded) {
            ScriptLoader.load(widgetOptions);
        }
        prevProps.current = {
            prevMiniPDP: miniPDP,
        };

        /* istanbul ignore next */
        return () => {
            const { attributes = {} } = window?.digitalData?.page;
            delete attributes?.dynamicGalleryModule;
        };
    }, []);

    useEffect(() => {
        const { prevMiniPDP } = prevProps.current;
        /* istanbul ignore next */
        if (miniPDP && miniPDP.miniPDPDGATC && !prevMiniPDP.miniPDPDGATC) {
            // To trigger miniPdpAtcFromSTL action to enable next add to cart.
            actions.miniPdpAtcFromDG && actions.miniPdpAtcFromDG(false);
        }
    }, [miniPDP]);

    /* istanbul ignore next */
    return (
        <div
            className={hasContent && dt(['mb-6'])}
            dangerouslySetInnerHTML={{ __html: stylisticsMarkup() }}
            id={DYNAMIC_GALLERIES_WIDGET_CONTAINER_ID}
        />
    );
};

DynamicGalleriesWidget.defaultProps = {
    actions: {},
    products: [],
};

DynamicGalleriesWidget.propTypes = {
    actions: oneOfType([array, object]),
    products: oneOfType([array, object]),
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            dynamicGalleriesItemClick,
            dynamicGalleriesLoadSuccess,
            dynamicGalleriesModuleClick,
            miniPDPOnDynamicGalleriesOpen,
        },
        dispatch
    ),
});

export default connect(null, mapDispatchToProps)(DynamicGalleriesWidget);
