/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { arrayOf, bool, number, object, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import Loader from 'yoda-core-components/lib/components/Loader/Loader';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import { isDesktopWidth } from 'yoda-core-components/lib/helpers/Utils/mediaQuery';
import Facet from '../Facet/Facet';
import FacetLabel from '../Facet/components/FacetLabel/FacetLabel';
import Search from '../StaticSearch/StaticSearch';
import PillWrapperConnected from '../PillWrapper/PillWrapper';
import CustomPriceRange from './components/CustomPriceRangeLazy';
import { getRandomId } from '../../utils';
import { filterCategoryPlaceHolder } from '../../configurations/config';

const compareKeys = (key, searchKey) => {
    const regex = new RegExp(searchKey, 'i');
    const keysMatch = regex.test(key);

    return !!keysMatch;
};

const FilterCategory = ({
    dataSource: config,
    dataSource: { facetId, facetName, facetType, facetValueList, searchAvailable },
    pillFormat,
    placeHolder,
}) => {
    const {
        context: {
            featureFlags: {
                enableMultiSelectFilters = false,
                enableFilterScrollTop = true,
                enableOverflowFilters = false,
            } = {},
        } = {},
        enableLoader = false,
        multiSelectFilter: { enableFacetLoader = false } = {},
    } = useSelector((store) => store);

    const filterContentRef = useRef(null);

    const [facets, setFacets] = useState(null);
    const [error, setError] = useState('');
    const [showAllFacets, setShowAllFacets] = useState(false);
    const [showBtnforfacts, setShowBtnforfacts] = useState(true);
    const isDesktopView = isDesktopWidth();
    const hasSearch = isDesktopView && searchAvailable;
    /* istanbul ignore next */
    const getAdjustedFacetValueList = (facetFlist) => {
        let adjustedFacetValueList = [];
        const facetListLength = facetFlist.length;

        if (facetListLength <= 14 || showAllFacets) {
            return facetFlist;
        }
        if (facetListLength >= 15 && !showAllFacets) {
            if (hasSearch) {
                adjustedFacetValueList = facetFlist.slice(0, 8);
            } else if (!hasSearch) {
                adjustedFacetValueList = facetFlist.slice(0, 10);
            }
        }

        return adjustedFacetValueList;
    };
    /* istanbul ignore next */
    const handleClick = (updateBtnstatus) => {
        setShowAllFacets(updateBtnstatus);
    };

    const getFacets = useCallback(
        (facetConfigs, fromFilterSearch = false) => {
            if (pillFormat)
                setFacets(<PillWrapperConnected config={config} placeHolder={placeHolder} />);
            setError('');
            if (fromFilterSearch && facetConfigs.length === 0) {
                setShowBtnforfacts(false);
                setFacets(
                    <div
                        className={dt([
                            'flex',
                            'mx-0',
                            'my-2',
                            'normal-case',
                            'text-small',
                            'text-sm',
                            'text-error-border',
                            '-mt-4',
                            'ml-4',
                        ])}
                    >
                        No results. Try again.
                    </div>
                );
                setError(dt(['!border-2', '!border-solid', '!border-error-border']));
                return;
            }
            /* istanbul ignore next */
            if (fromFilterSearch && facetValueList.length <= 14) {
                setShowBtnforfacts(false);
            } else if (fromFilterSearch && facetValueList.length >= 15) {
                setShowBtnforfacts(true);
            }

            if (enableFilterScrollTop) {
                filterContentRef.current.scrollTop = 0;
            }
            switch (facetType) {
                case 'prange':
                    setFacets(
                        <>
                            {facetConfigs.map((facetConfig) => (
                                <Facet
                                    config={config}
                                    facetConfig={facetConfig}
                                    key={getRandomId()}
                                    placeHolder={placeHolder}
                                    updateBtnStatus={handleClick}
                                />
                            ))}
                            <CustomPriceRange facetId={facetId} facetName={facetName} />
                        </>
                    );
                    break;

                case 'color':
                    setFacets(
                        <div
                            className={dt([
                                'flex',
                                'flex-wrap',
                                'justify-start',
                                'mx-2',
                                'group',
                                'after:flex-auto',
                            ])}
                        >
                            {facetConfigs.map((facetConfig) => (
                                <FacetLabel
                                    config={config}
                                    facetConfig={facetConfig}
                                    facetType={facetType}
                                    key={getRandomId()}
                                    large={isDesktopView}
                                    theme="default"
                                    updateBtnStatus={handleClick}
                                />
                            ))}
                        </div>
                    );
                    break;

                case 'externalLink':
                    setFacets(
                        <div
                            className={`${cx('clear-fix', 'external-links')} ${dt([
                                'sm:py-0',
                                'sm:px-4',
                                'lg:px-2',
                            ])}`}
                        >
                            {facetConfigs.map((facetConfig) => (
                                <div>
                                    <a
                                        className={`${dt([
                                            'inline-block',
                                            'mx-0',
                                            'my-2',
                                            'normal-case',
                                            'text-small',
                                        ])} ${cx('facetLink')}`}
                                        href={facetConfig.apiUrl}
                                    >
                                        {facetConfig.name}
                                    </a>
                                </div>
                            ))}
                        </div>
                    );
                    break;

                default:
                    setFacets(
                        <div>
                            {facetConfigs.map((facetConfig) => (
                                <Facet
                                    config={config}
                                    facetConfig={facetConfig}
                                    key={getRandomId()}
                                    placeHolder={placeHolder}
                                    updateBtnStatus={handleClick}
                                />
                            ))}
                        </div>
                    );
                    break;
            }
            // reassignBtnLogic(facetConfigs, fromFilterSearch);
        },
        [config, facetId, facetType, isDesktopView, pillFormat, placeHolder]
    );
    /* istanbul ignore next */
    const facetList =
        enableOverflowFilters && isDesktopView
            ? getAdjustedFacetValueList(facetValueList)
            : facetValueList;
    /* istanbul ignore next */
    const checkBtnDisplay = (fclen) => {
        if (fclen <= 14) {
            setShowBtnforfacts(false);
        } else if (fclen >= 15) {
            setShowBtnforfacts(true);
        }
    };
    /* istanbul ignore next */
    useEffect(() => {
        let searchValue = document.getElementById(`static-search-${facetName}`);
        searchValue = searchValue && searchValue.value;
        if (searchValue) {
            const fcList = facetValueList.filter((facetConfig) =>
                compareKeys(
                    facetConfig.name.replace(/[^a-zA-Z0-9 ]/g, ''),
                    searchValue.replace(/[^a-zA-Z0-9 ]/g, '')
                )
            );
            const getformedfacet = getAdjustedFacetValueList(fcList);
            getFacets(getformedfacet, true);
            checkBtnDisplay(fcList.length);
        } else {
            getFacets(facetList);
        }
    }, [facetValueList, getFacets, showAllFacets]);

    const maxHeightWrapperClass = `${
        hasSearch ? dt(['lg:max-h-[15.313rem]']) : dt(['lg:max-h-[11.25rem]'])
    }`;
    const wrapperClassName = `${!enableOverflowFilters && isDesktopView && maxHeightWrapperClass}
    ${dt(['lg:overflow-y-auto', 'lg:px-3'])}`;

    const filterCategoryConfig = {
        search: {
            onSearchChange: (searchValue) => {
                const fcList = facetValueList.filter((facetConfig) =>
                    compareKeys(
                        facetConfig.name.replace(/[^a-zA-Z0-9 ]/g, ''),
                        searchValue.replace(/[^a-zA-Z0-9 ]/g, '')
                    )
                );
                const getformedfacet = getAdjustedFacetValueList(fcList);
                getFacets(getformedfacet, true);
                checkBtnDisplay(fcList.length);
            },
            id: `static-search-${facetName}`,
            placeHolder: `Search ${facetName}`,
        },
        wrapper: {
            className: cx(wrapperClassName),
        },
    };
    /* istanbul ignore next */
    const renderShowMoreButton = () => {
        return (
            <button
                className={dt(['pl-0', 'underline'])}
                onClick={() => {
                    handleClick(!showAllFacets);
                }}
                type="button"
            >
                {showAllFacets ? 'See Less' : 'See More'}
            </button>
        );
    };
    /* istanbul ignore next */
    return (
        <div {...filterCategoryConfig.wrapper} ref={filterContentRef}>
            {enableFacetLoader && enableLoader && enableMultiSelectFilters && (
                <Loader
                    loaderClass={dt(['bg-light-white', 'pt-10', 'sm:z-[10]'])}
                    style={{ alignItems: 'flex-start' }}
                />
            )}
            {searchAvailable && !pillFormat && (
                <Search {...filterCategoryConfig.search} theme={error} />
            )}
            {facets}
            {enableOverflowFilters &&
                isDesktopView &&
                facetValueList.length >= 15 &&
                showBtnforfacts &&
                renderShowMoreButton()}
        </div>
    );
};

FilterCategory.propTypes = {
    dataSource: shape({
        facetValueList: arrayOf(object).isRequired,
        facetId: number,
        facetName: string,
        facetType: string,
        searchAvailable: bool,
    }).isRequired,
    pillFormat: bool,
    placeHolder: string,
};

FilterCategory.defaultProps = {
    pillFormat: false,
    placeHolder: filterCategoryPlaceHolder.filterPanel,
};

export default FilterCategory;
